/* src/components/ExplorePage.css */

.explore-page {
    padding: 20px;
    min-height: auto;
    overflow-y: auto !important;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 80px;
  }
  
  .level-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .level-item {
    width: 100%;
    max-width: 200px;
    margin: 10px;
    padding: 10px;
    padding-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .level-item h3 {
    margin: 0;
  }
  
  .level-item button {
    margin-top: 10px;
    padding: 5px 10px;
    border: 3px solid #1FB6FF;
    background-color: #66CDFF;
    box-shadow: 0 5px #1FB6FF;
    width: 100%;
    padding: 15px;
    color: white;
    border-radius: 10px;
    cursor: pointer;

    font-weight: bold;
    font-family: 'sofia-pro', sans-serif;
    font-size: 16px;
  }
  
  .create-level-button {
    width: 100%;
    flex-grow: 1;
    background-color: #FFC300;
    box-shadow: 0 5px #FFB020;
    border: 3px solid #FFB020;
    border-radius: 10px;
    padding: 15px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    height: 52px; /* Match the save button height */
    margin-bottom: 20px;
  }
  
  .create-level-button:active {
    box-shadow: none;
    transform: translateY(5px);
    transition: 0.1s;
  }

  .level-name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    color: #1FB6FF;
  }

  .level-tags {
    margin-top: 10px;
  }
  
  .level-tag {
    display: inline-block;
    margin-right: 5px;
    padding: 8px 8px;
    background-color: white;
    color: #1FB6FF;
    border-radius: 10px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Sofia Pro', sans-serif;
    transition: background-color 0.2s, color 0.2s;
    user-select: none;
    border: 2px solid #AFE5FF;

  }

  /* Filter Bar */
.filter-bar {
  margin-bottom: 20px;
}
.filter-bar-p {
  color: #1FB6FF;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Sofia Pro', sans-serif;
}

.filter-bar p {
  margin: 0 0 10px;
  font-weight: bold;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-tag-button {
  padding: 8px 8px;
  background-color: white;
  color: #1FB6FF;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Sofia Pro', sans-serif;
  transition: background-color 0.2s, color 0.2s;
  user-select: none;
  border: 2px solid #AFE5FF;
}

.filter-tag-button.active {
  background-color: #1FB6FF;
  color: white;
  border-color: #1FB6FF;
}

.filter-tag-button:focus {
  outline: none;
}

