.grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
  }
  
  .row {
    display: flex;
  }
  
  .grid-cell {
    width: 50px;
    height: 50px;
    border: 1px solid #AFE5FF;
    box-sizing: border-box;
  }


/* Top row cells */
.row:first-child .grid-cell {
  border-top: 2px solid #AFE5FF;
}

/* Bottom row cells */
.row:last-child .grid-cell {
  border-bottom: 2px solid #AFE5FF;
}

/* Left column cells */
.grid .row .grid-cell:first-child {
  border-left: 2px solid #AFE5FF;
}

/* Right column cells */
.grid .row .grid-cell:last-child {
  border-right: 2px solid #AFE5FF;
}

  
  /* Top left corner */
.row:first-child .grid-cell:first-child {
  border-top-left-radius: 10px;
}

/* Top right corner */
.row:first-child .grid-cell:last-child {
  border-top-right-radius: 10px;
}

/* Bottom left corner */
.row:last-child .grid-cell:first-child {
  border-bottom-left-radius: 10px;
}

/* Bottom right corner */
.row:last-child .grid-cell:last-child {
  border-bottom-right-radius: 10px;
}

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .grid .row .cell {
      /* Increase the size of the cells */
      width: 100px;
      height: 100px;
    }
  }
