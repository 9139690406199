
.main-section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px; /* To account for footer */
}

.leaderboard-list {
  width: 100%;
  overflow-y: auto;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 10px 20px;
  border-bottom: 1px solid #EEE;
  border-radius: 20px;
  background-color: white;
  margin-bottom: 10px;
}

.leaderboard-item.current-user {
  background-color: #EFFAFF;
}

.position-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1FB6FF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.position-number {
  color: white;
  font-weight: bold;
}

.user-name {
  color: #3C3C3C;
  font-weight: bold;
  flex-grow: 1;
}

.user-points {
  color: #3C3C3C;
  font-weight: bold;
}

/* Remove background colors from text */
.leaderboard-item .user-name,
.leaderboard-item .user-points {
  background-color: transparent;
}

/* Top three positions */
.leaderboard-item:nth-child(1) .user-name,
.leaderboard-item:nth-child(1) .user-points {
  color: #FFC300;
}

.leaderboard-item:nth-child(1) .position-circle {
  color: #FFC300;
  background-color: #FFC300;
}

.leaderboard-item:nth-child(2) .user-name,
.leaderboard-item:nth-child(2) .user-points {
  color: #C2C2C2;
}
.leaderboard-item:nth-child(2) .position-circle{
  color: #C2C2C2;
  background-color: #C2C2C2;
}

.leaderboard-item:nth-child(3) .user-name,
.leaderboard-item:nth-child(3) .user-points {
  color: #CC782E;
}
.leaderboard-item:nth-child(3) .position-circle{
  color: #CC782E;
  background-color: #CC782E;
}
