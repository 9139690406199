/* src/components/LevelCreator.css */

/* Level Creator Container */
.level-creator-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto !important;
}

.top-group {
  flex-shrink: 0;
}

.middle-group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the grid vertically */
  margin-bottom: 10px;
}

.bottom-group {
  flex-shrink: 0;
}


/* Responsive Design */
@media (max-width: 600px) {
  .level-creator-container {
    padding: 0 10px;
  }

  .inventory-items,
  .catalog-items {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

@media (min-width: 600px) {
  .inventory-items,
  .catalog-items {
    overflow: hidden;
  }
}


/* Header */
.header {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.settings-button,
.exit-button {
  flex-shrink: 0; /* Prevent buttons from shrinking */
  background: none;
  border: none;
  font-size: 24px;
  color: #BABABA;
  cursor: pointer;
}

.level-title-container {
  flex: 1; /* Allow it to grow and shrink */
  margin: 0 10px;
  min-width: 0; /* Allow it to shrink properly */
}

.level-name-input {
  width: 100%;
  padding: 8px;
  font-size: 20px;
  font-weight: bold;
  border: none;
  outline: none;
  background-color: #EFFAFF;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}


/* Goal Section */
.goal-section {
}

.level-creator-container h3 {
  font-family: 'Sofia Pro', sans-serif;
  font-weight: bold;
  color: #1FB6FF;
  margin: 0px;
  margin-top: 5px;
}

.level-creator-container p {
  font-family: 'Sofia Pro', sans-serif;
  color: #555;
  font-weight: 500;
  margin: 0px;
}

.bulb-indicators {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bulb-container-goal {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}

.bulb-goal {
  width: 100%;
  height: 100%;
}


/* Create Puzzle Section */
.create-puzzle-section {
  margin-top: 20px;
}

.create-puzzle-section h3 {
  color: #1FB6FF;
  margin-bottom: 10px;
}


/* Inventory Section */
.inventory-section {
  border: 2px dashed #1FB6FF;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: #EFFAFF;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 98px; /* Set a minimum height */
  overflow: visible; /* Ensure overflow is visible */
}

.inventory-placeholder {
  color: #1FB6FF !important;
  font-size: 14px;
  text-align: center;
  padding: 20px;
}

.inventory-navigation {
  display: flex;
  align-items: center;
}

.inventory-arrow, .catalog-arrow {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-size: 24px;
  color: #BABABA;
  cursor: pointer;
}

.inventory-arrow:disabled, .catalog-arrow:disabled  {
  cursor: default;
  visibility: hidden; /* Hide but keep space */
}


.inventory-items, .catalog-items {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible; /* Ensure overflow is visible */
}

.draggable-item {
  position: relative;
  overflow: visible;
}

/* Remove Button */
.remove-button {
  position: absolute;
  top: -12px;
  left: -12px; /* Move to top left */
  background: none;
  border: none;
  color: #cfcfcf;
  font-size: 24px;
  cursor: pointer;
}

.remove-button:hover {
  color: #FF6B6B;
}




/* Catalog Section */
.catalog-section {

}

.catalog-component-section {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 98px; /* Set a minimum height */
  overflow: visible; /* Ensure overflow is visible */
}

.catalog-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Bottom Buttons */

/* Bottom Buttons */
.bottom-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 0px;
}

.save-button {
  background-color: #7BC625;
  box-shadow: 0 5px #5BA61C;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 52px;
  height: 52px; /* Ensure it's square */
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button:active {
  box-shadow: none;
  transform: translateY(5px);
  transition: 0.1s;
}

.check-button {
  flex-grow: 1;
  background-color: #FFC300;
  box-shadow: 0 5px #FFB020;
  border: none;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  height: 52px; /* Match the save button height */
}

.check-button:active {
  box-shadow: none;
  transform: translateY(5px);
  transition: 0.1s;
}


/* Misc */

.catalog-navigation {
  display: flex;
  align-items: center;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}


