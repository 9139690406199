.test-button-sch {
    width: 100%;
    box-sizing: border-box;

    min-height: 1em; /* Base height */
    font-size: 1rem; /* Adjust font size as needed */

    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: none;
  }



.test-button-sch:active {
    box-shadow: none !important;
    transform: translateY(5px);
    transition: 0.1s;
  }

  .test-button-sch:hover {
    filter: brightness(105%);
    transition: 0.1s;
  }
  