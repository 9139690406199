/* src/components/TipPopup.css */

.tip-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .tip-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    text-align: center;
  }
  
  .tip-popup-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .tip-popup-close-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .tip-popup-close-button:hover {
    background-color: #0056b3;
  }
  