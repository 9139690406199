/* Header.css */
/* Header.css */
.header-container {
  background-color: #AFE5FF;
  width: 100%;
}

.header-content {
  max-width: 1000px; /* Set your desired max width */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

  
  .logo {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #1FB6FF;
    margin: 0;
  }
  
  .points-container {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 5px 10px;
    border-radius: 50px; /* Fully curved pill shape */
  }
  
  .points {
    margin-left: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  

  .bug-button {
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 15px;
    cursor: pointer;
    border: 3px solid #1FB6FF;
    background-color: #66CDFF;
    box-shadow: 0 5px #1FB6FF;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .bug-button:hover {
    filter: brightness(105%);
  }
  
  .bug-button:active {
    box-shadow: none;
    transform: translateY(5px);
  }
  

  .menu {
    display: flex;
    gap: 20px;
  }