@keyframes slideUp {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

@keyframes slideDown {
  from {
    bottom: 0;
  }
  to {
    bottom: -100%;
  }
}

.overlay.slide-in {
  animation: slideUp 0.2s forwards;
}

.overlay.slide-out {
  animation: slideDown 0.2s forwards;
}

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: #FFF1C5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  border-radius: 20px 20px 0 0;
  transition: bottom 0.2s ease-in-out;
}

.overlay-content {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  color: white;
  text-align: left;
  border-radius: 20px 20px 0 0;
  margin-bottom: 84px;
}

.continue-button, .try-again-button {
  width: 80%;
  box-sizing: border-box;
  padding: 20px 15px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-style: none;
  margin-bottom: 30px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  outline-offset: -2px;
}

.continue-button {
  background-color: #7BC625;
  box-shadow: 0 5px #5BA61C;
  color: white;
  outline: 2px solid #5BA61C;
}

.continue-button:active {
  box-shadow: none !important;
  transform: translate(-50%, 5px);
  transition: 0.1s;
}

.continue-button:hover {
  filter: brightness(105%);
  transition: 0.1s;
}

.try-again-button {
  background-color: #FFC300;
  box-shadow: 0 5px #FFB020;
  color: white;
  outline: 2px solid #FFB020;
}

.try-again-button:active {
  box-shadow: none !important;
  transform: translate(-50%, 5px);
  transition: 0.1s;
}

.try-again-button:hover {
  filter: brightness(105%);
  transition: 0.1s;
}

.tips-list {
  margin-top: 20px;
  text-align: left;
}

.tip-message {
  font-weight: 600;
  font-size: 18px;
  font-family: 'sofia-pro', sans-serif;
  color: #FFA500;
}

.educational-message {
  font-size: 16px;
  font-weight: 600;
  font-family: 'sofia-pro', sans-serif;
  margin-top: 10px;
}

/* Success message color */
.educational-message.success {
  color: #5BA61C; /* Green color for success */
}

/* Failure message color */
.educational-message.failure {
  color: #FFA500; /* Orange color for failure */
}

.tips-h2 {
  font-weight: 600;
  font-size: 24px;
  font-family: 'sofia-pro', sans-serif;
  margin: 0;
}

.tip-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.tip-image {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

.star-text-container {
  display: flex;
  align-items: center;
}

.star-image {
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.arrow-icon {
  font-size: 24px;
  margin: 0 10px;
  color: #FFA500;
}

/* Overlay.css */

/* Button to toggle detailed hints 
.detailed-hints-button {
  background: none;
  border: none;
  color: #007BFF;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
*/
/* Container for detailed hints 
.detailed-hints {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.detailed-hints p {
  margin: 5px 0;
  color: #333;
}

*/