.question {
  text-align: center;
  /* margin-bottom: 20px; */
}

.question h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.bulb-indicators {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 250px; /* Adjust width to accommodate up to 4 bulbs in a row */
  margin: 8px auto; /* Center the container */
}


.bulb-container-goal {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  background-color: white;
}

@media (max-width: 768px) {
  .bulb-container-goal {
    width: 40px; /* Adjusted width for mobile */
    height: 40px; /* Adjusted height for mobile */
  }

  .bulb-indicators {
    margin: 12px auto; /* Center the container */
  }
}


.bulb-goal {
  width: 100%;
  height: 100%;
}

.small-bulb-icon {
  height: 40px;
  vertical-align: middle;
}
.no-wrap {
  white-space: nowrap;
}


