/* src/components/Auth.css */

/* Container for the authentication pages */
.auth-container {
  background-color: #F5F5F5;
  max-width: 400px;
  margin: 80px auto; /* Centers the container vertically and horizontally */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-container h2 {
  text-align: center;
  color: #1FB6FF;
  font-family: 'Sofia Pro', sans-serif;
  margin-bottom: 30px;
}

.auth-container form {
  display: flex;
  flex-direction: column;
}

.auth-container input[type="email"],
.auth-container input[type="name"],
.auth-container input[type="password"] {
  padding: 12px;
  margin-bottom: 20px;
  border: 2px solid #CCC;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Sofia Pro', sans-serif;
}

.auth-container input:focus {
  border-color: #1FB6FF;
  outline: none;
}

/* Adjusted button styles */
.auth-container button[type="submit"] {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 15px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 3px solid #1FB6FF;
  background-color: #66CDFF;
  box-shadow: 0 5px #1FB6FF;
  color: white;
  transition: all 0.2s ease-in-out;
}

.auth-container button[type="submit"]:hover {
  filter: brightness(105%);
}

.auth-container button[type="submit"]:active {
  box-shadow: none;
  transform: translateY(5px);
}


.auth-container p {
  text-align: center;
  font-family: 'Sofia Pro', sans-serif;
}

.auth-container p span {
  color: #1FB6FF;
  cursor: pointer;
  text-decoration: underline;
}

.auth-container p span:hover {
  color: #1A91D9;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .auth-container {
    margin: 40px 20px;
    padding: 30px;
  }

  .auth-container h2 {
    font-size: 24px;
  }

  .auth-container input[type="email"],
  .auth-container input[type="password"] {
    font-size: 14px;
  }

  .auth-container button[type="submit"] {
    font-size: 16px;
  }
}

.error-message {
  color: #E53B3B;
  font-family: 'Sofia Pro', sans-serif;
  text-align: center;
  margin-top: 10px;
}

/* Message styling */
.message {
  color: #5BA61C; /* Success messages in blue */
  font-family: 'Sofia Pro', sans-serif;
  text-align: center;
  margin-top: 10px;
}


.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
  color: #434343;
  transition: color 0.2s ease-in-out;
}

.close-icon:hover {
  color: #f00;
}