/* Overlay */
.settings-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(175, 229, 255, 0.3); /* AFE5FF with 30% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Container */
.settings-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

/* Header */
.settings-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-popup-header h2 {
  margin: 0;
  font-size: 24px;
}

.close-button-popup {
  background: none;
  border: none;
  font-size: 24px;
  color: #FF6B6B;
  cursor: pointer;
}

.close-button-popup:hover {
  color: #FF6B6B;
}

/* Grid Size Section */
.grid-size-section {
  margin-top: 20px;
}

.grid-size-inputs {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.input-group {
  display: flex;
  flex-direction:row;
  flex: 1;
  background-color: #AFE5FF;
  padding: 4px;
  border-radius: 10px;
  align-items: center;
}

.input-group label {
  font-weight: bold;
  font-size: 18px;
  color: #1FB6FF;
  margin-left: 4px;
}

.input-group input {
  background-color: white;
  padding: 10px;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  font-size: 16px;
  margin: 0px;
  width: 40px;
  margin-left: auto;
  text-align: center;
}

/* Message Sections */
.message-group {
  margin-top: 20px;
}

.message-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}

.success-message-group label {
  color: #7BC625; /* Green color */
}

.try-again-message-group label {
  color: #FFC300; /* Yellow color */
}

.message-group textarea {
  width: 100%;
  height: 90px;
  padding: 10px;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  resize: none;
  font-family: 'Sofia Pro', sans-serif;
}

/* Save and Cancel Buttons */
.settings-popup-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.settings-popup-buttons button {
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.save-button {
  background-color: #7BC625;
  color: white;
  box-shadow: 0 5px #5BA61C;
}

.save-button:active {
  box-shadow: none;
  transform: translateY(5px);
}

.save-button:disabled {
  background-color: #ccc; /* Gray background for disabled state */
  color: #666; /* Adjust text color for better contrast */
  box-shadow: none; /* Remove shadow when disabled */
  cursor: not-allowed; /* Change cursor to indicate non-interactive state */
}

.cancel-button {
  background-color: #EE312B;
  color: white;
  box-shadow: 0 5px #C83D3D;
}

.cancel-button:active {
  box-shadow: none;
  transform: translateY(5px);
}

.tags-input-section {
  margin-top: 20px;
}

.tags-input-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #1FB6FF; /* Matching the label color with the input-group */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tag-item {
  padding: 8px 8px;
  background-color: white;
  color: #1FB6FF;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Sofia Pro', sans-serif;
  transition: background-color 0.2s, color 0.2s;
  user-select: none;
  border: 2px solid #AFE5FF;
}

.tag-item.selected {
  background-color: #1FB6FF;
  color: white;
  border-color: #1FB6FF;
}
