/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-close-button {
    padding: 5px 18px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 15px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px solid #1FB6FF;
    background-color: #66CDFF;
    box-shadow: 0 5px #1FB6FF;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .modal-close-button:hover {
    filter: brightness(105%);
  }
  
  .modal-close-button:active {
    box-shadow: none;
    transform: translateY(5px);
  }
  