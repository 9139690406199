.main-section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 80px; /* To account for footer */
}

.account-item {
  margin-bottom: 15px;
}

.account-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-label {
  color: #1FB6FF;
  font-weight: bold;
}

.field-value,
input {
  width: 100%;
  color: #1FB6FF;
  margin-top: 5px;
  border: none;
  border-bottom: 1px solid #CCC;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 500;
}

input {
  background: #EFFAFF;
  font-family: 'Sofia Pro', sans-serif;
}

.icon-button {
  background: none;
  border: none;
  font-size: 1.5rem;
}



.edit-button {
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
  border: 3px solid #1FB6FF;
  background-color: #66CDFF;
  box-shadow: 0 5px #1FB6FF;
  color: white;
  transition: all 0.2s ease-in-out;
}

.edit-button:hover {
  filter: brightness(105%);
}

.edit-button:active {
  box-shadow: none;
  transform: translateY(5px);
}


.icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Ensures buttons are aligned to the right */
  gap: 2px; /* Adds a small space between the buttons */
}

/* Adjust colors of icons */
.icon-button i.fa-check-circle {
  color: #7BC625;
}

.icon-button i.fa-times-circle {
  color: #E53B3B;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .account-item {
    margin-bottom: 20px;
  }

  .account-item-header {
    flex-direction: row;
    align-items: center;
  }

  .field-label {
    flex-grow: 1;
  }

  .icon-button,
  .edit-button {
    margin-left: auto;
  }
}

.logout-label {
  color: #1FB6FF;
  cursor: pointer;
  text-decoration: underline;
}

.logout-label:hover {
  color: #007BFF;
}
