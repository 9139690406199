.bulb-container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  box-shadow: 0 5px #AFE5FF;
  background-color: white;
}

.bulb {
  width: 50px;
  height: 50px;
}

.draggable-item {
  width: 60px;
  height: 60px;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  box-shadow: 0 5px #AFE5FF;
  background-color: white;
  transition: box-shadow 0.3s, background-color 0.3s, transform 0.3s;
  position: relative;
}

.draggable-item.dragged {
  box-shadow: 0 -5px #AFE5FF;
  background-color: #F5F5F5;
  cursor: not-allowed; /* Change cursor to indicate it can't be dragged */
  transform: translateY(5px); /* Move down by 5px */
}


.draggable-item.no-remaining {
  box-shadow: 0 -5px #AFE5FF;
  background-color: #F5F5F5;
  cursor: not-allowed;
  transform: translateY(5px);
  opacity: 0.5;
}

.draggable-item.no-remaining img {
  display: none;
}


.item-count {
  position: absolute;
  bottom: 0px; /* Adjust as needed */
  right: 0px; /* Adjust as needed */
  color: #1FB6FF;
  font-weight: bold;
  font-size: 12px;
  padding: 2px 5px; /* Optional: Add padding for better appearance */
  border-radius: 3px; /* Optional: Add border-radius for rounded corners */
}

.resistance-value {
  position: absolute;
  bottom: 0px; /* Adjust as needed */
  left: 0px; /* Adjust as needed */
  color: #1FB6FF;
  font-weight: bold;
  font-size: 12px;
  padding: 2px 5px; 
  border-radius: 3px; 
}


/* Media query for mobile devices */

  .draggable-item {
    width: 70px;
    height: 70px;
    flex-wrap: wrap;
    row-gap: 15px;
    gap: 10px;
  }
  .draggable-item img {
    width: 70px;
    height: 70px;
  }
  .item-count {
    font-size: 16px;
  }

  /* Animation */

.draggable-item-animate {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}


