/* Overlay */
.resistor-value-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(175, 229, 255, 0.3); /* AFE5FF with 30% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Container */
.resistor-value-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
}

/* Header */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  font-size: 24px;
  color: #1FB6FF;
}

.close-button-popup {
  background: none;
  border: none;
  font-size: 24px;
  color: #FF6B6B;
  cursor: pointer;
}
.close-button-popup:hover {
  color: #FF6B6B;
}

/* Instruction Text */
.instruction-text {
  margin-top: 10px;
  font-size: 16px;
  color: #3C3C3C !important;
}

/* Resistor Values Grid */
.resistor-values-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}

.resistor-value-button {
  border: 2px solid #AFE5FF;
  border-radius: 10px;
  box-shadow: 0 5px #AFE5FF;
  background-color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.3s, background-color 0.3s, transform 0.3s;
  font-weight: bold;
  color: #3C3C3C;

}

.resistor-value-button.selected {
  box-shadow: 0 5px #1FB6FF;
  border-color: #1FB6FF;
  color: #1FB6FF;
  font-weight: bold;
}

.resistor-value-button:active {
  box-shadow: none;
  transform: translateY(5px);
}

/* Unit Toggle and Custom Input */
.unit-toggle-and-custom-input {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
}

.unit-toggle {
  flex: 1;
  display: flex;
  border: 2px solid #AFE5FF;
  background-color: #AFE5FF;
  box-shadow: 0 5px #AFE5FF;
  border-radius: 10px;
  overflow: hidden;
}

.unit-button {
  flex: 1;
  background-color: #AFE5FF;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #1FB6FF;
  font-weight: bold;
  cursor: pointer;
}

.unit-button.active {
  background-color: white;
  border-radius: 9px;
}

/* Custom Input Wrapper */
.custom-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.custom-value-input{
  flex: 1;
  padding: 10px;
  padding-right: 38px; 
  font-size: 16px;
  border: 2px solid #AFE5FF;
  box-shadow: 0 5px #AFE5FF;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 0px;
}

.unit-symbol {
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: #1FB6FF;
  font-weight: bold;
}



/* Save Changes Button */
.save-changes-button {
  margin-top: 20px;
  width: 100%;
  background-color: #7BC625;
  box-shadow: 0 5px #5BA61C;
  border: none;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.save-changes-button:active {
  box-shadow: none;
  transform: translateY(5px);
}
