.grid-cell {
    /*width: 60px;*/
    /*height: 60px;*/
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .hovered {
    background-color: lightblue;
  }
  
 /* For the green outline on the next available cell */
.grid-cell .next-available {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #7BC625; /* Green outline */
  background-color: transparent;
  z-index: 1; /* Ensure it's on top of other content */
  transition: transform 0.5s ease-out, border-color 0.2s ease;
  animation: glide 1s ease-out infinite;
}

/* Animation for the glowing effect 
@keyframes glide {
  0% { box-shadow: 0px 0px 10px 2px #7BC625; }
  100% { box-shadow: 0px 0px 20px 2px #7BC625; }
}*/
  
  .grid-item {
    width: 50px;
    height: 50px;
    transition: transform 0.2s ease;
  }


  .resistance-value-grid {
  position: absolute;
  color: #1FB6FF;
  font-weight: bold;
  font-size: 12px;

}

  
  /* src/components/GridCell.css */
.edit-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 255;
  background-color: #1FB6FF;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
}

/* Animation rotation */
.grid-cell-animate-rotate .grid-item {
  animation: rotateHint 1.5s infinite, pulseGlow 1.5s infinite;
  cursor: pointer; /* Change the cursor to a pointer to indicate interactivity */
  border-radius: 10px;
  background-color: white;
  z-index: 99;
  transform-origin: center;
}

@keyframes rotateHint {
  0% {
    transform: rotate(var(--initial-rotation)) scale(1);
  }
  10% {
    transform: rotate(calc(var(--initial-rotation) - 10deg)) scale(1.1);
  }
  20% {
    transform: rotate(calc(var(--initial-rotation) + 10deg)) scale(1.1);
  }
  30% {
    transform: rotate(calc(var(--initial-rotation) - 10deg)) scale(1.1);
  }
  40% {
    transform: rotate(calc(var(--initial-rotation) + 10deg)) scale(1.1);
  }
  50% {
    transform: rotate(var(--initial-rotation)) scale(1);
    
  }
}

@keyframes pulseGlow {
  0%, 100% {
    box-shadow: 0 0 0px rgba(31, 182, 255, 0.5);
  }
  25% {
    box-shadow: 0 0 15px rgba(31, 182, 255, 0.8);
  }
}

/* New animation class for 'growGlow' */
.grid-cell-animate-growGlow .grid-item {
  animation: growGlow 1.5s infinite;
  cursor: pointer; /* Indicates interactivity */
  pointer-events: auto; 
  border-radius: 10px;
  background-color: white;
  z-index: 99;
}


@keyframes growGlow {
  0% {
    transform: rotate(var(--initial-rotation)) scale(1);
    box-shadow: 0 0 0px rgba(31, 182, 255, 0.5);
  }
  25% {
    transform: rotate(var(--initial-rotation)) scale(1.2);
    box-shadow: 0 0 15px rgba(31, 182, 255, 0.8);
  }
  50% {
    transform: rotate(var(--initial-rotation)) scale(1);
    box-shadow: 0 0 0px rgba(31, 182, 255, 0.5);
  }
}
