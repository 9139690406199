/* Footer.css */
/* Footer.css */
.footer-container {
    background-color: #AFE5FF;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  
  .footer-content {
    max-width: 1000px; /* Same as header */
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    padding-bottom: 15px;
    gap: 7px;
  }
  
  
  .footer-button {
    width: 100%;
    padding: 12px 20px;
    font-size: 1.5rem;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px solid #1FB6FF;
    background-color: #66CDFF;
    box-shadow: 0 5px #1FB6FF;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .footer-button:hover {
    filter: brightness(105%);
  }
  
  .footer-button:active {
    box-shadow: none !important;
    box-shadow: none;
    transform: translateY(5px);
    transition: 0.1s;
  }

  