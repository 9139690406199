
/* html, body {
    overflow-y: auto !important; 
  } 
   */

   .exit-button {
    font-size: 30px; /* Adjust font size to match your design */
    color: #BABABA; /* Button text color */
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .exit-button:hover {
    color: #FD4D50;
    transition: 500ms;
  }
  
  .progress-bar-container {
    position: relative;
    flex-grow: 1;
    height: 10px; /* Height of the progress bar */
    background-color: #E5E5E5; /* Light grey background */
    border-radius: 5px; /* Rounded corners */
    margin-left: 16px; /* Space between button and progress bar */
    margin-right: 16px; /* Space between button and progress bar */
  }
  
  .progress-bar {
    height: 100%;
    background-color: #95D151; /* Darker grey for the progress */
    border-radius: 5px; /* Inherit the same border radius */
    transition: width 0.5s ease; /* Smooth transition for the progress bar */
  }
  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px; /* Adjust padding to match your design */
    background-color:white; /* Background color of the header */
  }  

  /* src/components/Level.css */

.app {
    text-align: center;
    overflow: hidden;
    height: 100%; /* Ensure that no scroll occurs */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100dvh;
  }

  .draggable-items {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .result-message {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .refresh-button {
    margin: 20px; /* Adjust as needed */
  }
  
  @media (max-width: 768px) {
    .app {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100dvh;
    }
    
     .question-component {

    } 
    
    .refresh-button {
      align-self: center;
      margin-bottom: 20px;
    }
  
    .result-message {
      align-self: center;
      margin: 20px 0;
    }
  }
  @media (max-width: 1024px) {
    .top-container {
      width: 90%;
      margin: 0 auto;
    }  
  }
  @media (min-width: 1024px) {
    .top-container {
      width: 1024px;
      padding: 20px 15px;
      margin: 0 auto;
    }
  }