/* src/components/MainHomePage.css */
.MainHomePage-Body {
  overflow:scroll;
}
/* Header box with full width */
.header-box {
    background-color: #AFE5FF; /* Light blue background */
    width: 100vw; /* Full viewport width */
    height: 60px; /* Fixed height for centering */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Header text */
  .header-box h1 {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 24px; /* Set font size */
    font-weight: bold;
    color: #1FB6FF; /* Font color */
    margin: 0;
  }
  
  /* The wrapper for the rest of the page content */
  .main-homepage-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto !important;
  }

  /* Wrapper for image and h2 to be centered vertically between header and button */
.ImgWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1; 
    text-align: center;
    width: 100%;
    max-width: 400px;
  }
  
  /* Main section that takes the available height */
  .main-section {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    height: calc(100vh - 60px); /* Subtract header height from 100vh */
    max-height: calc(100vh - 60px); /* Subtract header height from 100vh */
    width: 100%;
    max-width: 800px;
    padding: 20px;
    box-sizing: border-box;
    position: relative; /* Set position for absolute positioning of button */
  }
  
  /* Banner image */
  .banner-image {
    width: 100%;
    height: auto;
    margin-top: 20px;
    height: calc(100vh - 376px);
    object-fit: contain
  }
  
  /* Description */
  .main-section h2 {
    font-size: 1.4rem;
    font-weight: normal;
    text-align: center;
    margin: 20px;
    color: #3C3C3C;
    font-weight: 600;
  }
  
  /* Button positioned at the bottom */
  .start-learning-button {
    background-color: #007BFF;
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto; /* Push the button to the bottom of the section */
    align-self: center;
  }
  
  .start-learning-button:hover {
    background-color: #0056b3;
  }
  
  /* Additional content below the main section */
  .additional-content {
    padding: 20px;
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
  
  /* Responsive styling for desktop view */
  @media (min-width: 1024px) {
  
    .banner-image {
      max-width: 400px;
    }
  
    .start-learning-button {
      font-size: 1.3rem;
      padding: 12px 30px;
    }
  }

  
  /* Container for the text and button */
.button-container {
    background-color: #AFE5FF; /* Light blue background */
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Header Text inside the container */
  .button-container h3 {
    color: #1FB6FF; /* Bright blue text color */
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0px; /* Space between text and button */
    margin-bottom: 10px;
  }
  
  /* Modified button with matching corner radius */
  .play-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem; /* Adjust font size as needed */
    font-weight: bold;
    border-radius: 15px; /* Smaller radius for the button */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 3px solid #1FB6FF; /* Inside stroke of 3px in blue */
    background-color: #66CDFF; /* Fill color */
    box-shadow: 0 5px #1FB6FF; /* Shadow with blue color */
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .play-button:active {
    box-shadow: none !important;
    transform: translateY(5px);
  }
  
  .play-button:hover {
    filter: brightness(105%);
  }
  

  /* Container for the whole about section */
.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    max-width: 800px; /* Adjust as needed */
  }
  
  /* Reusing the button-container for title */
  .title-container {
    background-color: #AFE5FF; /* Light blue background */
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  /* Title Heading */
  .title-container h3 {
    color: #1FB6FF;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  
  /* Subheading */
  .title-container p {
    color: #1FB6FF;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
  }
  
  /* Content Styling */
  .content {
    color: #3C3C3C;
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.1rem;
    text-align: justify;
    margin-bottom: 30px;
  }
  .content li {
    margin-bottom: 10px;
  }
  
  /* Image Container */
  .section-image {
    text-align: center;
  }
  
  .section-image img {
    max-width: 100%;
    height: auto;
    border-radius: 20px; /* Optional, can remove or adjust */
  }
  
  /* Media Query for larger screens */
  @media (min-width: 1024px) {
    .main-section, .section {
      max-width: 800px; /* Set the same width for both */
      margin: 0 auto;
      box-sizing: border-box;
    }
  }
  

  /* Add these styles to src/components/MainHomePage.css */
.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-items: stretch;
}
/* Style for login and signup buttons */
.login-button,
.signup-button,
.guest-login-button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 15px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 3px solid #1FB6FF;
  background-color: #66CDFF;
  box-shadow: 0 5px #1FB6FF;
  color: white;
  transition: all 0.2s ease-in-out;
}

.login-button:hover,
.signup-button:hover,
.guest-login-button:hover {
  filter: brightness(105%);
}

.login-button:active,
.signup-button:active,
.guest-login-button:active {
  box-shadow: none;
  transform: translateY(5px);
}


/* Specific style for the login button */
.login-button {
  background-color: white;
  color: #1FB6FF;
}

/* No change for signup button, keeping the original style */
.signup-button {
  background-color: #66CDFF;
  color: white;
}

.guest-login-button {
  background-color: #66CDFF;
  color: white;
}

