/* src/components/HomePage.css */

/* HomePage.css */
.main-section {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (80 + 80)); /* Replace with actual heights if known */
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
}

.homepage h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.homepage p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.level-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 250px; /* Adjust width to accommodate up to 4 buttons in a row */
  margin: 0 auto; /* Center the container */
}


.level-button {
  cursor: pointer;
}

.level-button:hover {
  opacity: 0.9;
}

.level-button:active {
  opacity: 0.7;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close-button:hover {
  opacity: 0.7;
}

.circuit-board-menu {
  width: 90%;
}

@media (min-width: 1024px) {
  .circuit-board-menu {
    width: 500px; /* Adjust this value to the desired width for desktop */
  }
}


.filter-complete {
  filter: url(#filter-complete);
}

.filter-incomplete {
  filter: url(#filter-incomplete);
}


.title-container {
  background-color: #AFE5FF; /* Light blue background */
  border-radius: 20px;
  padding: 20px;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Title Heading */
.title-container h3 {
  color: #1FB6FF;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

/* Subheading */
.title-container p {
  color: #1FB6FF;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.1rem;
  font-weight: normal;
  margin: 0;
}

/* Circuit board container */
.circuit-board-wrapper {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}


  /* Container for the text and button */
  .button-container {
    background-color: #AFE5FF; /* Light blue background */
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  /* Header Text inside the container */
  .button-container h3 {
    color: #1FB6FF; /* Bright blue text color */
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    margin: 0px; /* Space between text and button */
    margin-bottom: 10px;
  }


  /* Toggle Styles */
.toggle-wrapper {
  margin: 20px 0;
}

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 3px solid #AFE5FF;
  border-radius: 20px;
  background: #AFE5FF;
  font-weight: bold;
  color: #AFE5FF;
  cursor: pointer;
}

.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 20px;
  background: white;
  transition: all 0.3s;
}

.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}

.toggleContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}

.toggleCheckbox {
  display: none;
}

.toggleCheckbox:checked + .toggleContainer div:first-child {
  color: white;
  transition: color 0.3s;
}

.toggleCheckbox:checked + .toggleContainer div:last-child {
  color: #AFE5FF;
  transition: color 0.3s;
}

.toggleCheckbox + .toggleContainer div:first-child {
  color: #AFE5FF;
  transition: color 0.3s;
}

.toggleCheckbox + .toggleContainer div:last-child {
  color: white;
  transition: color 0.3s;
}


/* Subheading */
.bonus {
  padding-top: 10px;
  color: #1FB6FF;
  font-family: 'Sofia Pro', sans-serif;
  font-size: 1.1rem;
  font-weight: normal;
  margin: 0;
  text-align: center;
}

/* src/components/HomePage.css */

.header-box {
  position: relative;
}

.logout-button {
  position: absolute;
  right: 20px;
  top: 15px;
  background-color: transparent;
  border: none;
  color: #1FB6FF;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Sofia Pro', sans-serif;
}

.logout-button:hover {
  color: #007BFF;
}
