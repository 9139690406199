.test-button {
  max-width: 1000px;
  box-sizing: border-box;
  padding: 20px 15px;
  min-height: 1em; /* Base height */
  font-size: 1rem; /* Adjust font size as needed */
  font-weight: bold;

  border-radius: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-style: none;
  background-color: #FFC300;
  box-shadow: 0 5px #FFB020;
  border: 3px solid #FFB020;
  color: white;
  margin-bottom: 30px;
}

.test-button:active {
  box-shadow: none !important;
  transform: translateY(5px);
  transition: 0.1s;
}

.test-button:hover {
  filter: brightness(105%);
  transition: 0.1s;
}


@media (max-width: 1024px) {
  .test-button {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    padding: 20px 15px;
  }
}
@media (min-width: 1024px) {
  .test-button {
    width: 840px;
    padding: 20px 15px;
  }
}

.Modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
