.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
}

/* Outer toggle track */
.toggle-switch {
  position: relative;
  width: 70px;
  height: 35px;
  background-color: #AFE5FF;
  border-radius: 10px;
  border: 3px solid #AFE5FF;
  box-shadow: 0 5px #AFE5FF;
}

/* The left & right icons always visible */
.icon-left,
.icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  z-index: 1;
  user-select: none;
  -webkit-touch-callout: none;
}

.icon-left {
  left: 4px;
}

.icon-right {
  right: 4px;
}

/* White “selector” that slides between left and right */
.selector {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 7px;
  transition: left 0.3s;
  z-index: 0;
}

/* When in schematic mode, move the selector to the right */
.toggle-switch.schematic .selector {
  left: 34px; /* Adjust as needed */
}
